const cash = [
  {
    text: '业务模块名称',
    value: 'moduleName',
    sortable: false,
    align: 'center',
  },
  {
    text: '机构名称',
    value: 'organizationName',
    sortable: false,
    align: 'center',
  },
  {
    text: '分析项目',
    value: 'itemsName',
    sortable: false,
    align: 'center',
  },
  {
    text: '柜台名称',
    value: 'locationName',
    sortable: false,
    align: 'center',
  },
  {
    text: '摄像头名称',
    value: 'cameraName',
    sortable: false,
    align: 'center',
  },
  {
    text: '区域名称',
    value: 'areaName',
    sortable: false,
    align: 'center',
  },
  {
    text: '任务编号',
    value: 'taskNo',
    sortable: false,
    align: 'center',
  },
  {
    text: '预警时间',
    value: 'warningTime',
    sortable: false,
    align: 'center',
  },
  {
    text: '预警内容',
    value: 'description',
    sortable: false,
    align: 'center',
  },
  {
    text: '金额',
    value: 'amount',
    sortable: false,
    align: 'center',
  },
  {
    text: '状态',
    value: 'statusStr',
    sortable: false,
    align: 'center',
  },
  {
    text: '处理标识',
    value: 'handleFlagStr',
    sortable: false,
    align: 'center',
  },
  {
    text: '合规标识',
    value: 'violationFlagStr',
    sortable: false,
    align: 'center',
  },
  {
    text: '开始时间',
    value: 'startTime',
    sortable: false,
    align: 'center',
  },
  {
    text: '结束时间',
    value: 'endTime',
    sortable: false,
    align: 'center',
  },
  {
    text: '操作人名称',
    value: 'operatorName',
    sortable: false,
    align: 'center',
  },
  {
    text: '审核人名称',
    value: 'reviewName',
    sortable: false,
    align: 'center',
  },
];
const trans = [
  {
    text: '业务模块名称',
    value: 'moduleName',
    sortable: false,
    align: 'center',
  },
  {
    text: '机构名称',
    value: 'organizationName',
    sortable: false,
    align: 'center',
  },
  {
    text: '分析项目',
    value: 'itemsName',
    sortable: false,
    align: 'center',
  },
  {
    text: '柜台名称',
    value: 'locationName',
    sortable: false,
    align: 'center',
  },
  {
    text: '摄像头名称',
    value: 'cameraName',
    sortable: false,
    align: 'center',
  },
  {
    text: '区域名称',
    value: 'areaName',
    sortable: false,
    align: 'center',
  },
  {
    text: '任务编号',
    value: 'taskNo',
    sortable: false,
    align: 'center',
  },
  {
    text: '预警时间',
    value: 'warningTime',
    sortable: false,
    align: 'center',
  },
  {
    text: '预警内容',
    value: 'description',
    sortable: false,
    align: 'center',
  },
  {
    text: '金额',
    value: 'amount',
    sortable: false,
    align: 'center',
  },
  {
    text: '状态',
    value: 'statusStr',
    sortable: false,
    align: 'center',
  },
  {
    text: '处理标识',
    value: 'handleFlagStr',
    sortable: false,
    align: 'center',
  },
  {
    text: '合规标识',
    value: 'violationFlagStr',
    sortable: false,
    align: 'center',
  },
  {
    text: '开始时间',
    value: 'startTime',
    sortable: false,
    align: 'center',
  },
  {
    text: '结束时间',
    value: 'endTime',
    sortable: false,
    align: 'center',
  },
  {
    text: '操作人名称',
    value: 'operatorName',
    sortable: false,
    align: 'center',
  },
  {
    text: '审核人名称',
    value: 'reviewName',
    sortable: false,
    align: 'center',
  },
  {
    text: '交易账户',
    value: 'tranAccount',
    sortable: false,
    align: 'center',
  },
  {
    text: '交易类型',
    value: 'tranCode',
    sortable: false,
    align: 'center',
  },
  {
    text: '人员证件号',
    value: 'customerCard',
    sortable: false,
    align: 'center',
  },
  {
    text: '人员编号',
    value: 'customerNo',
    sortable: false,
    align: 'center',
  },
  {
    text: '人员类型',
    value: 'customerTypeStr',
    sortable: false,
    align: 'center',
  }
];

const obj = { cash, trans };
export default obj;
